import { createDefault } from "../utils/ConstUtils";

const defaultNoticeRule = [
  { value: 10, text: "商品" },
  { value: 10001, text: "新規登録" },
  { value: 10002, text: "承認・却下" },
  { value: 10003, text: "ステータス遷移" },
  { value: 10004, text: "重要項目以外変更" },
  { value: 10005, text: "重要項目変更" },
  { value: 10006, text: "削除" },
  { value: 10007, text: "卸掛率変更" },
  // 受注
  // { value: 11000, text: "受注" },
  { value: 11001, text: "新規登録（分割・ﾌｫｰｷｬｽﾄ含む）" },
  { value: 11002, text: "ステータス遷移" },
  { value: 11003, text: "重要項目以外変更" },
  { value: 11004, text: "重要項目変更" },
  { value: 11005, text: "削除" },
  // 発注
  // { value: 12000, text: "発注" },
  { value: 12001, text: "新規登録" },
  { value: 12002, text: "ステータス遷移(*1)" },
  { value: 12003, text: "変更承認" },
  { value: 12004, text: "重要項目以外変更" },
  { value: 12005, text: "重要項目変更" },
  { value: 12006, text: "削除" },
  // 入荷
  { value: 13, text: "入荷" },
  { value: 13001, text: "新規登録" },
  { value: 13002, text: "ステータス遷移" },
  { value: 13003, text: "変更承認" },
  { value: 13004, text: "重要項目以外変更" },
  { value: 13005, text: "重要項目変更" },
  { value: 13006, text: "削除" },
  // 出荷
  { value: 14, text: "出荷" },
  { value: 14004, text: "新規登録" },
  { value: 14005, text: "商品" },
  // 在庫
  { value: 15, text: "在庫" },
  { value: 15001, text: "新規登録" },
  // 請求
  { value: 16, text: "請求" },
  { value: 16002, text: "ステータス遷移" },
  // 販売店返品
  { value: 17, text: "販売店返品" },
  { value: 17001, text: "新規登録" },
  { value: 17003, text: "実績入力・項目変更" },
  // メーカー返品
  { value: 18, text: "メーカー返品" },
  { value: 18001, text: "新規登録" },
  { value: 18003, text: "実績入力・項目変更" },
  // 不足
  { value: 19, text: "不足" },
  { value: 19001, text: "新規登録" },
  { value: 19003, text: "実績入力・項目変更" },
  // 委託消化仕入
  { value: 20, text: "委託消化仕入" },
  { value: 20001, text: "新規登録" },
  { value: 20002, text: "項目変更" },
  { value: 20003, text: "削除" },
  { value: 20005, text: "ステータス遷移" },
  { value: 20011, text: "明細新規登録" },
  { value: 200012, text: "明細項目変更" },
  { value: 200013, text: "明細削除" },
];

const cafereoNoticeRule = [
  { value: 11, text: "受注" },
  { value: 12, text: "発注" },
];
export const CafereoNoticeRule = createDefault([...defaultNoticeRule, ...cafereoNoticeRule]);

const vendorNoticeRule = [{ value: 11, text: "発注" }];
export const VendorNoticeRule = createDefault([...defaultNoticeRule, ...vendorNoticeRule]);

const makerNoticeRule = [{ value: 12, text: "受注" }];
export const MakerNoticeRule = createDefault([...defaultNoticeRule, ...makerNoticeRule]);

export default createDefault([...defaultNoticeRule, ...cafereoNoticeRule]);
