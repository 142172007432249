import * as Api from "./../../libs/api-client";

const actions = {
  async create(_, transferModel) {
    return Api.dispatchPost("/transfer/create", transferModel);
  },
  async update(_, transferModel) {
    return Api.dispatchPost("/transfer/update", transferModel);
  },
  async bulkupdate(_, transferModel) {
    return Api.dispatchPost("/transfer/bulkupdate", transferModel);
  },
  async cancel(_, transferModel) {
    return Api.dispatchPost("/transfer/cancel", transferModel);
  },
  async badTransfer(_, transferModel) {
    return Api.dispatchPost("/transfer/bad-transfer", transferModel);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
