import * as Api from "./../../libs/api-client";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async history(_, condition) {
    return Api.dispatchGet("/proposition/history", { propositionId: condition });
  },
  async search(_, condition) {
    return Api.dispatchGet("/proposition/search", condition);
  },
  async entry(_, condition) {
    return Api.dispatchPost("/proposition/create", condition);
  },
  async update(_, condition) {
    return Api.dispatchPost("/proposition/update", condition);
  },
  async remove(_, condition) {
    return Api.dispatchPost("/proposition/delete", condition);
  },
  async approval(_, condition) {
    return Api.dispatchPost("/proposition/approval-use", condition);
  },
  async complete(_, condition) {
    return Api.dispatchPost("/proposition/approval-check", condition);
  },
  async print(_, condition) {
    return Api.dispatchPost("/proposition/out-applicationform", condition);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
