<template>
  <v-app>
    <v-main id="main">
      <v-container fluid>
        <v-row justify="center" align-content="center" style="height: 500px">
          <v-col cols="12" sm="8" md="6">
            <v-card class="elevation-12 rounded-0">
              <div class="pa-8">
                <span style="image-rendering: -webkit-optimize-contrast"
                  ><v-img :src="require('../../assets/delphoi_top.png')" width="150"></v-img>
                </span>
                <v-card-title class="h6 font-weight-black">システムメンテナンス中です。</v-card-title>
                <v-card-title class="h6 font-weight-black">しばらくお待ちください。</v-card-title>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
#main {
  background: linear-gradient(0deg, #f9c2c2, #ffd244);
}
</style>

<script>
export default {
  name: "Maintenace",
};
</script>
