const state = {
  loading: {
    enabled: false,
    message: "",
  },
  defaultPageSize: 100,
  gridHeightSize: 575,
};

const getters = {
  isLoading(state) {
    return state.loading.enabled;
  },
  loadingMessage(state) {
    return state.loading.message;
  },
  defaultPageSize(state) {
    return state.defaultPageSize;
  },
  gridHeightSize(state) {
    return state.gridHeightSize;
  },
  adjustGridSize(state) {
    return state.adjustGridSize;
  },
};

const mutations = {
  loading(state, value) {
    if (typeof value === "object") {
      state.loading = {
        enabled: Boolean(value.enabled),
        message: value.message == null ? "" : value.message,
      };
    } else {
      state.loading = {
        enabled: value,
        message: "",
      };
    }
  },
  pagesize(state, value) {
    state.defaultPageSize = value;
  },
  gridResize(state, value) {
    state.gridHeightSize = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
