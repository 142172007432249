import * as Api from "./../../libs/api-client";

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet(`/loft/search-carton`, condition);
  },
  async create(_, loftModel) {
    return Api.dispatchPost("/loft/create-carton", loftModel);
  },
  async update(_, loftModel) {
    return Api.dispatchPost("/loft/update-carton", loftModel);
  },
  async remove(_, loftModel) {
    return Api.dispatchPost("/loft/delete-carton", loftModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/loft/history-carton", { cartonNumber: condition });
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
};
