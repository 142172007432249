import * as Api from "../../libs/api-client";

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/arrivalPlan/search", condition);
  },
  async outData(_, condition) {
    let arrivalPlanModel = condition.contents;
    let header = condition.header;
    return Api.dispatchPost("/arrivalPlan/out-data", arrivalPlanModel, header);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
