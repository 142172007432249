// Doc: https://blog.u-chan-chi.com/post/vue-cognito/
export default {
  // カフェレオAWS
  // AWSConfig: {
  //   Region: "ap-northeast-1",
  //   UserPoolId: "ap-northeast-1_7V0piXFbd",
  //   ClientId: "237couhria49klho0qohdm1n86",
  //   IdentityPoolId: "ap-northeast-1:f54b6c2c-8b65-464e-a771-c42943b6b438",
  // },
  //INQ AWS
  AWSConfig: {
    Region: "ap-northeast-1",
    UserPoolId: "ap-northeast-1_lUFG5fwC2",
    ClientId: "7im25s8ln622md1f6uie81pi8i",
    IdentityPoolId: "ap-northeast-1:ae06a8d2-4358-4455-ad7e-8d336da7d182",
  },
};
