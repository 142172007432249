import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/arrival/search", condition);
  },
  async delete(_, arrivalModel) {
    return Api.dispatchPost("/arrival/delete", arrivalModel);
  },
  async handle(_, arrivalModel) {
    return Api.dispatchPost("/arrival/handle", arrivalModel);
  },
  async import(_, importModel) {
    let response = RequestUtils.procBulkData2(importModel, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/arrival/import", bulkModel, header);
    });
    return response;
  },
  async history(_, condition) {
    return Api.dispatchGet("/arrival/history", condition);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
