<template>
  <v-card>
    <dialog-bar @expand="onExpandClick($event)"></dialog-bar>
    <v-card-title class="text-h6"><v-icon>mdi-bullhorn</v-icon>通知設定</v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle class="mt-2 pb-3"
      >チェックを入れた通知がご自身のメールアドレス( {{ mailAddress }} )宛に送信されます</v-card-subtitle
    >
    <v-divider inset></v-divider>
    <v-card-text :style="{ height: expand ? '100%' : '450px' }">
      <v-treeview selectable :items="noticeRules" v-model="userNoticeRules" open-all dense></v-treeview>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { CafereoNoticeRule, VendorNoticeRule, MakerNoticeRule } from "../../consts/NoticeRule";
import DialogBar from "../../components/common/DialogBar.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
export default {
  name: "NotificationSetting",
  components: {
    DialogBar,
  },
  data() {
    return {
      noticeRules: [],
      userNoticeRules: [],
      expand: false,
      defaultNoticeRule: [],
    };
  },
  beforeMount() {
    this.init();
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    mailAddress() {
      return this.$store.getters["security/loggedInUser"].mailAddress;
    },
  },
  methods: {
    init() {
      this.searchNotice();
      if (this.isCafereoUser) this.defaultNoticeRule = CafereoNoticeRule;
      if (this.isVendorUser) this.defaultNoticeRule = VendorNoticeRule;
      if (this.isMakerUser) this.defaultNoticeRule = MakerNoticeRule;
    },
    reset() {
      this.init();
    },
    async searchNotice() {
      try {
        this.loadingOn();
        const settingTreeResponse = await this.$store.dispatch("notice/getNoticeSettingTree", {});
        if (ApiStatus.isSystemError(settingTreeResponse.data?.header)) {
          return this.redirectError();
        }
        const settingResponse = await this.$store.dispatch("notice/getNoticeSetting", {});
        if (ApiStatus.isSystemError(settingResponse.data?.header)) {
          return this.redirectError();
        }
        var settingTreeResult = settingTreeResponse.data.contents;
        var noticeRules = [];
        settingTreeResult.settingTrees.forEach((row) => {
          let items = [];
          row.name = this.defaultNoticeRule.of(row.id);
          row.children.forEach((childrenRow) => {
            items.push({
              id: childrenRow,
              name: this.defaultNoticeRule.of(childrenRow),
            });
          });
          noticeRules.push({
            id: row.id,
            name: this.defaultNoticeRule.of(row.id),
            children: items,
          });
        });
        this.noticeRules = noticeRules;
        var settingResult = settingResponse.data.contents;
        this.userNoticeRules = settingResult.notifications;
      } catch (error) {
        console.error("NotificationSetting::searchNotice", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onUpdateClick() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("notice/updateNoticeetting", {
          notifications: this.userNoticeRules,
        });
        switch (response.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            this.$dialog.notify.info(`通知設定が更新されました`, { timeout: 2300 });
            this.$emit("submit");
            this.reset();
            break;
          case ApiStatus.consts.BUSINESS_ERROR:
          case ApiStatus.consts.ALREADY_CHANGED:
            this.$dialog.warning({
              title: "通知設定",
              text: "",
              actions: ["OK"],
            });
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("PasswordChange::onSubmitClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onCancelClick() {
      this.$emit("close");
      this.reset();
    },
    onExpandClick(value) {
      this.expand = value;
      this.$emit("expand", value);
    },
  },
};
</script>
