import moment from "moment";

export const sleep = (ms, error) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      error ? reject() : resolve();
    }, ms);
  });
};

export const count = async (table, filters) => {
  return await getAll(table, filters).count();
};

export const find = async (table, filters, sort) => {
  if (filters == null || filters.length === 0) {
    return await table.orderBy(sort).toArray();
  } else {
    return findAll(table, filters, sort);
  }
};

export const findOne = async (table, filters) => {
  return await table.filter(every(...filters)).first();
};

export const getAny = (table, matches) => {
  const _matches = Array.isArray(matches) ? matches : [matches];
  return table.filter(some(..._matches));
};

export const findAny = async (table, matches, sort) => {
  // return await table.filter(some(...matches)).sortBy(sort);
  return await getAny(table, matches).sortBy(sort);
};

export const modifyAny = async (table, matches, modifier) => {
  return await getAny(table, matches).modify(modifier);
};

export const getAll = (table, filters) => {
  const _filters = Array.isArray(filters) ? filters : [filters];
  return table.filter(every(..._filters));
};

export const findAll = async (table, filters, sort) => {
  // return await table.filter(every(...filters)).sortBy(sort);
  return await getAll(table, filters).sortBy(sort);
};

export const modifyAll = async (table, filters, modifier) => {
  return await getAll(table, filters).modify(modifier);
};

export const lookup = async (table, index, value, projections) => {
  const _value = value == null ? "" : value;
  const record = await table.where(index).equals(_value).first();
  return projections == null ? record : extract(record, projections);
};

export const extract = (object, keys, initial) => {
  const single = !Array.isArray(keys);
  const _keys = single ? [keys] : keys;
  const value = Array(_keys.length).fill(initial);
  if (object) {
    for (let i = 0; i < _keys.length; i++) {
      value[i] = object[_keys[i]];
    }
  }
  return single ? value[0] : value;
};

export const every = (...funcs) => {
  return (r) => {
    for (let i = 0; i < funcs.length; i++) {
      if (!funcs[i](r)) return false;
    }
    return true;
    // return funcs.map((func) => func(r)).every((v) => v);
  };
};

export const some = (...funcs) => {
  return (r) => {
    for (let i = 0; i < funcs.length; i++) {
      if (funcs[i](r)) return true;
    }
    return false;
    // return funcs.map((func) => func(r)).some((v) => v);
  };
};

export const and = (...funcs) => {
  return (r) => every(...funcs)(r);
};

export const or = (...funcs) => {
  return (r) => some(...funcs)(r);
};

export const not = (func) => {
  return (r) => !func(r);
};

export const truthy = (index) => {
  return (r) => {
    const dbvalue = r[index];
    return Boolean(dbvalue);
  };
};

export const falsy = (index) => {
  return (r) => {
    const dbvalue = r[index];
    return !dbvalue;
  };
};

export const isNull = (index) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue == null;
  };
};

export const notNull = (index) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue != null;
  };
};

export const equals = (index, value) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue == value;
  };
};

export const notEquals = (index, value) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue != value;
  };
};

export const gt = (index, value) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue == null ? false : dbvalue > value;
  };
};

export const gte = (index, value) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue == null ? false : dbvalue >= value;
  };
};

export const lt = (index, value) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue == null ? false : dbvalue < value;
  };
};

export const lte = (index, value) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue == null ? false : dbvalue <= value;
  };
};

export const like = (index, regexp) => {
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return regexp.test(dbvalue);
  };
};

export const isBlank = (index) => {
  return (r) => {
    const dbvalue = r[index];
    return dbvalue == null || String(dbvalue).length === 0;
  };
};

export const startWith = (index, value) => {
  // DB: String / INPUT: String
  // -> [index] like [value]%
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return String(dbvalue).startsWith(value);
  };
};

export const endWith = (index, value) => {
  // DB: String / INPUT: String
  // -> [index] like %[value]
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return String(dbvalue).endsWith(value);
  };
};

export const contains = (index, value) => {
  // DB: String / INPUT: String
  // -> [index] like %[value]%
  // DB: Array / INPUT: String
  // -> [value] in (...[index])
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    if (Array.isArray(dbvalue)) {
      // Array.includes(String)
      return dbvalue.includes(value);
    } else {
      // String.includes(String)
      return String(dbvalue).includes(value);
    }
  };
};

export const includes = (index, values) => {
  // DB: String / INPUT: Array
  // -> [index] in (...[values])
  // DB: Array / INPUT: Array
  // -> ...[index] in (...[values])
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    if (Array.isArray(dbvalue)) {
      return dbvalue.some((v) => values.includes(v));
    } else {
      // Array.includes(String)
      return values.includes(dbvalue);
    }
  };
};

export const between = (index, from, to) => {
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return dbvalue >= from && dbvalue <= to;
  };
};

export const dateFrom = (index, value) => {
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    const dbdate = moment(dbvalue);
    const valuedate = moment(value, "YYYY MM DD", false).startOf("days");
    return dbdate.isSameOrAfter(valuedate);
  };
};

export const dateTo = (index, value) => {
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    const dbdate = moment(dbvalue);
    const valuedate = moment(value, "YYYY MM DD", false).endOf("days");
    return dbdate.isSameOrBefore(valuedate);
  };
};

export const dateEquals = (index, value, format = "YYYY/MM/DD", unit = "days") => {
  // dbvalue is value ( dbvalue = value )
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return moment(dbvalue, format).isSame(value, unit);
  };
};

export const dateAfter = (index, value, format = "YYYY/MM/DD HH:mm:ss") => {
  // dbvalue is after value ( dbvalue > value )
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return moment(dbvalue, format).isAfter(value);
  };
};

export const dateSameOrAfter = (index, value, format = "YYYY/MM/DD HH:mm:ss") => {
  // dbvalue is after value or equals value ( dbvalue >= value )
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return moment(dbvalue, format).isSameOrAfter(value);
  };
};

export const dateBefore = (index, value, format = "YYYY/MM/DD HH:mm:ss") => {
  // dbvalue is before value ( dbvalue < value )
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return moment(dbvalue, format).isBefore(value);
  };
};

export const dateSameOrBefore = (index, value, format = "YYYY/MM/DD HH:mm:ss") => {
  // dbvalue is before value or equals value ( dbvalue <= value )
  return (r) => {
    const dbvalue = r[index];
    if (dbvalue == null) {
      return false;
    }
    return moment(dbvalue, format).isSameOrBefore(value);
  };
};
