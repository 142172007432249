<template>
  <v-card>
    <v-card-title class="text-h6">
      <v-icon>mdi-badge-account-horizontal</v-icon>プロフィール
      <v-spacer></v-spacer>
      <v-btn fab x-small outlined @click="$emit('close')"><v-icon>mdi-close-thick</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="ユーザID" dense readonly :value="loginUser.userId"></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field label="ユーザ名" dense readonly :value="loginUser.userName"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="法人CD" dense readonly :value="loginUser.corporateCode"></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field label="法人名" dense readonly :value="loginUser.corporateName"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field label="所属" dense readonly :value="loginUser.groupName"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field label="連絡先電話番号" dense readonly :value="loginUser.telNumber"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field label="メールアドレス" dense readonly :value="loginUser.mailAddress"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field label="権限" dense readonly :value="loginUser.role"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="パスワード"
                type="password"
                value="********"
                append-icon="mdi-pencil"
                dense
                readonly
                @click:append="$emit('password')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { statuses as ApiStatus } from "../../libs/api-client";
import { CafereoPrivileges, MakerPrivileges, VendorPrivileges } from "../../consts/UserPrivileges";

export default {
  name: "UserProfile",
  props: ["user", "show"],
  data() {
    return {
      loginUser: {
        userId: null,
        userName: null,
        corporateCode: null,
        corporateName: null,
        groupName: null,
        telNumber: null,
        mailAddress: null,
        role: null,
      },
    };
  },
  beforeMount() {
    this.init();
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  watch: {
    show(show) {
      if (show) this.init();
    },
  },
  methods: {
    async init() {
      try {
        this.loadingOn();
        var params = { userId: this.user.userId };
        const response = await this.$store.dispatch("user/loginUser", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result) {
          this.loginUser.userId = result.user.userId;
          this.loginUser.userName = result.user.userName;
          this.loginUser.corporateCode = result.user.corporationCd;
          this.loginUser.corporateName = result.user.corporationName;
          this.loginUser.groupName = result.user.groupName;
          this.loginUser.telNumber = result.user.phoneNo;
          this.loginUser.mailAddress = result.user.mailAddress;
          var availablePrivileges = "";
          if (this.isCafereoUser) {
            availablePrivileges = CafereoPrivileges.of(result.user.privilege);
          } else if (this.isMakerUser) {
            availablePrivileges = MakerPrivileges.of(result.user.privilege);
          } else if (this.isVendorUser) {
            availablePrivileges = VendorPrivileges.of(result.user.privilege);
          }
          this.loginUser.role = availablePrivileges;
        }
      } catch (error) {
        console.error("UserProfile::init", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
