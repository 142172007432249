import * as Api from "./../../libs/api-client";

const actions = {
  async search(_, searchModel) {
    return Api.dispatchGet("/product/get-wholesale-rate", searchModel);
  },
  async calc(_, corporationRateModel) {
    return Api.dispatchPost("/product/calc-wholesale-rate", corporationRateModel);
  },
  async update(_, corporationRateModel) {
    return Api.dispatchPost("/product/update-wholesale-rate", corporationRateModel);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
