import { Buffer } from "buffer";
import zlib from "zlib";

export const compress = (obj) => {
  const json = JSON.stringify(obj);
  const raw = zlib.gzipSync(json).toString("base64");
  return raw;
};

export const decompress = (raw) => {
  const bytes = Buffer.from(raw, "base64");
  const json = zlib.unzipSync(bytes).toString("utf-8");
  const obj = JSON.parse(json);
  return obj;
};
