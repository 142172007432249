import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import BaseViewMixin from "./mixins/baseView";
import CustomDatePicker from "./components/common/pickers/DatePicker.vue";

Vue.config.productionTip = false;

// Vue.config.errorHandler = (err, vm, info) => {
//   console.error(`Captured in Vue.config.errorHandler: ${info}`, err, vm);
//   document.title = `【ERROR】${process.env.VUE_APP_SYSTEM_NAME}`;
// };

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    actions: { true: { text: "はい", color: "primary" }, false: "いいえ" },
  },
});

Vue.component("vue-timepicker", VueTimepicker);
Vue.component("dp-date-picker", CustomDatePicker);

Vue.mixin(BaseViewMixin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
