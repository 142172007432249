import * as Api from "./../../libs/api-client";

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet(`/corporation/search`, condition);
  },
  async create(_, corporationModel) {
    return Api.dispatchPost("/corporation/create", corporationModel);
  },
  async update(_, corporationModel) {
    return Api.dispatchPost("/corporation/update", corporationModel);
  },
  async remove(_, corporationModel) {
    return Api.dispatchPost("/corporation/delete", corporationModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/corporation/history", { corporateCode: condition });
  },
  async suggest(_, condition) {
    return Api.dispatchGet(`/corporation/suggest`, condition);
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
};
