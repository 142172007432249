import { createDefault, valueOf } from "../utils/ConstUtils";

export default createDefault(
  [
    { value: 1, text: "カフェレオ", enum: "CAFEREO", path: "cafereo", hidden: true },
    { value: 2, text: "メーカー", enum: "MAKER", path: "supplier" },
    { value: 3, text: "販売店", enum: "VENDOR", path: "sales" },
  ],

  (constant, items) => {
    constant.getPath = (corporationType) => {
      return valueOf(items, corporationType, "value", "path");
    };
  }
);
