<template>
  <v-system-bar window>
    <slot name="title"></slot>
    <v-spacer></v-spacer>
    <slot name="handle"></slot>
    <v-btn icon small v-if="ismaximum" @click="onToggle()"
      ><v-icon small class="ma-0">mdi-arrow-collapse</v-icon></v-btn
    >
    <v-btn icon small v-else @click="onToggle()"><v-icon small class="ma-0">mdi-arrow-expand</v-icon></v-btn>
    <slot name="icons"></slot>
  </v-system-bar>
</template>

<script>
export default {
  name: "DialogBar",
  props: { maximum: { type: Boolean, default: false } },
  data() {
    return {
      ismaximum: this.maximum,
    };
  },
  watch: {
    maximum(value) {
      this.ismaximum = value;
    },
  },
  methods: {
    onToggle() {
      this.ismaximum = !this.ismaximum;
      this.$emit("expand", this.ismaximum);
    },
  },
};
</script>
