<template>
  <div class="ma-3" style="min-width: 120px">
    <v-hover v-slot:default="{ hover }">
      <v-card :to="{ name: link }" :elevation="hover ? 6 : 2">
        <v-card-title class="justify-center pa-2">
          <v-icon class="mr-1" dense>{{ icon }}</v-icon>
          <span class="caption"><slot></slot></span>
        </v-card-title>
      </v-card>
    </v-hover>
  </div>
  <!-- <div>
    <v-hover v-slot:default="{ hover }">
      <v-card :to="{ name: link }" :elevation="hover ? 6 : 2">
        <v-layout justify-center>
          <v-icon>{{ icon }}</v-icon>
        </v-layout>
        <v-card-title class="justify-center">
          <span class="caption"><slot></slot></span>
        </v-card-title>
      </v-card>
    </v-hover>
  </div> -->
</template>

<script>
export default {
  name: "ShortcutMenu",
  props: ["link", "icon"],
};
</script>
