import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoRefreshToken } from "amazon-cognito-identity-js";
import { Config, CognitoIdentityCredentials } from "aws-sdk";
import Congito from "../../cognito";
import PermissionMap from "./../../permissions.json";
import CorporateType from "../../consts/CorporationTypes";
import CustomStorage from "../../libs/custom-storage";
import * as Api from "./../../libs/api-client";

const state = {
  loginUser: null,
  accessToken: null,
  // refreshToken: null,
  // idToken: null,
  currentUser: null,
  allowedActions: null,
  userPool: null,
  // tokenExp: null,
  serviceSetting: null,
};

const getters = {
  loggedIn(state) {
    return state.loginUser != null && state.accessToken?.length > 0;
  },
  loggedInUser(state) {
    return state.loginUser;
  },
  loggedInUserId(state) {
    return state.loginUser?.userId;
  },
  userAllowedAction(state) {
    return state.allowedActions || [];
  },
  displayname(state) {
    if (!state.loginUser) {
      return "";
    }
    return `${state.loginUser.groupName} / ${state.loginUser.userName}`;
  },
  isCafereo(state) {
    return state.loginUser?.corporateType === 1;
  },
  isFtrans(state) {
    // 権限周りでエフトランスはカフェレオに含まれることになった→不要になるはず
    // return state.loginUser?.corporateType === 8;
    return state.loginUser?.corporateType === 1;
  },
  isMaker(state) {
    return state.loginUser?.corporateType === 2;
  },
  isVendor(state) {
    return state.loginUser?.corporateType === 3;
  },
  // idToken(state) {
  //   return state.idToken;
  // },
  // tokenExp(state) {
  //   return state.tokenExp;
  // },
  userPool(state) {
    return state.userPool;
  },
  // refreshToken(state) {
  //   return state.refreshToken;
  // },
  serviceSetting(state) {
    return state.serviceSetting;
  },
};

const mutations = {
  login(state, model) {
    state.loginUser = model.user;
    state.accessToken = "dummy";
    //state.refreshToken = "dummy";
  },
  logout(state) {
    state.loginUser = null;
    state.accessToken = null;
    // state.refreshToken = null;
    // state.idToken = null;
    state.allowedActions = null;
  },
  setPermissions(state, model) {
    const allowedActions = [];
    for (const permissionId in PermissionMap) {
      const permission = PermissionMap[permissionId];
      const level = permission[model.user.role];
      if (level === 1) {
        allowedActions.push(permissionId);
      } else if (level === 2) {
        if (model.user.special?.includes(permission.SPECIAL)) {
          allowedActions.push(permissionId);
        }
      }
    }
    state.allowedActions = allowedActions;
    console.debug("AllowedActions", model.user.role, allowedActions, allowedActions.length);
  },
  setUserPool(state, userPool) {
    state.userPool = userPool;
  },
  // setIdToken(state, idToken) {
  //   state.idToken = idToken;
  // },
  // setTokenExp(state, exp) {
  //   state.tokenExp = exp;
  // },
  // setRefreshToken(state, refreshToken) {
  //   state.refreshToken = refreshToken;
  // },
  setServiceSetting(state, serviceSetting) {
    state.serviceSetting = serviceSetting;
  },
};

const actions = {
  signin({ dispatch }, loginModel) {
    return new Promise((resolve, reject) => {
      Config.region = Congito.AWSConfig.region;
      Config.credentials = new CognitoIdentityCredentials({
        IdentityPoolId: Congito.AWSConfig.IdentityPoolId,
      });
      const cognitoUserPool = new CognitoUserPool({
        UserPoolId: Congito.AWSConfig.UserPoolId,
        ClientId: Congito.AWSConfig.ClientId,
        Storage: CustomStorage,
      });
      // vuex-persistedstateがfunctionを保存できないためこれは意味なくなる
      //commit("setUserPool", cognitoUserPool);
      const cognitoUser = new CognitoUser({
        Username: loginModel.loginId,
        Pool: cognitoUserPool,
        Storage: CustomStorage,
      });
      //cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");
      const authenticationDetails = new AuthenticationDetails({
        Username: loginModel.loginId,
        Password: loginModel.password,
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          console.log("authenticate:", result);
          CustomStorage.setItem("updatingIdToken", false);
          CustomStorage.setItem("setIdToken", result.getIdToken().jwtToken);
          CustomStorage.setItem("setRefreshToken", result.getRefreshToken().getToken());
          CustomStorage.setItem("setTokenExp", result.getIdToken().payload.exp);
          if (result.getIdToken().payload["delphoi:inActivated"] == "true") {
            reject("無効ユーザー");
          } else {
            const userinfo = await dispatch("authenticate", result.getIdToken().payload);
            resolve(userinfo);
          }
        },
        onFailure: (err) => {
          console.error("signin", err);
          reject(err);
        },
      });
    });
  },
  async authenticate({ commit }, loginModel) {
    let user = {
      userId: loginModel["delphoi:user_id"],
      loginId: loginModel["cognito:username"],
      userName: loginModel["delphoi:user_name"],
      mailAddress: loginModel["delphoi:mail_address"],
      corporateCode: loginModel["delphoi:corporation_code"],
      corporateName: loginModel["delphoi:corporation_name"],
      groupCode: loginModel["delphoi:group_cd"],
      groupName: loginModel["delphoi:group_name"],
      applicationGroup: CorporateType.getPath(loginModel["delphoi:corporation_type"]),
      corporateType: parseInt(loginModel["delphoi:corporation_type"]),
      role: loginModel["delphoi:user_role"],
      special: loginModel["delphoi:specials"].split(","),
      allowForecast: loginModel["delphoi:allowed_forecast"],
    };
    user.special = user.special.map(Number);
    console.log("user:", user);

    commit("login", { user });

    let response = await Api.commonGet("/system/init-webcl");
    const serviceSetting = response.data.contents.serviceSetting;
    commit("setServiceSetting", serviceSetting);
    commit("setPermissions", { user });

    return { authenticated: true, user };
  },
  async updateToken({ getters }) {
    // ログイン時に保管していたアクセストークンの有効期限（１秒余裕を持って）
    let exp = (await CustomStorage.getItem("setTokenExp")) * 1000 - 1000;
    let now = new Date().getTime();
    console.log("exp:", exp, " now:", now);
    // アクセストークの有効期限が切れていた場合
    if (now > exp) {
      await CustomStorage.setItem("updatingIdToken", true);
      console.log("try update id token");
      // vuex-persistedstateがfunctionを保存できないため
      //const cognitoUser = cognitoUserPool.getCurrentUser();
      const cognitoUserPool = new CognitoUserPool({
        UserPoolId: Congito.AWSConfig.UserPoolId,
        ClientId: Congito.AWSConfig.ClientId,
        Storage: CustomStorage,
      });
      let user = getters.loggedInUser;
      const cognitoUser = new CognitoUser({
        Username: user.loginId,
        Pool: cognitoUserPool,
        Storage: CustomStorage,
      });
      const refreshToken = new CognitoRefreshToken({ RefreshToken: CustomStorage.getItem("setRefreshToken") });

      await cognitoUser.refreshSession(refreshToken, async function (err, session) {
        if (session == null) {
          CustomStorage.setItem("updatingIdToken", "error");
          return;
        }
        if (session.isValid()) {
          // IDトークン
          CustomStorage.setItem("setIdToken", session.getIdToken().jwtToken);
          CustomStorage.setItem("setRefreshToken", session.getRefreshToken().getToken());
          CustomStorage.setItem("updatingIdToken", false);
          // アクセストークの有効期限
          CustomStorage.setItem("setTokenExp", session.getIdToken().payload.exp);
        } else {
          CustomStorage.setItem("updatingIdToken", "error");
          return;
        }
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
