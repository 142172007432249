import * as Api from "./../../libs/api-client";
// import StockOwners from "../../consts/StockOwners";
import RequestUtils from "./../../utils/RequestUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    const statedPurchases = {
      unsanction: [], // 決済待ち(purchaseStatus:1)
      sanctionRejected: [], // 決裁却下(purchaseStatus:2)
      unchangeApproved: [], // 変更承認待ち(changeStatus: 2)
      changeRejected: [], // 変更却下(changeStatus: 3)
      unapproved: [], // 承認待ち(purchaseStatus:3)
      rejected: [], // 却下(purchaseStatus:4)
      approved: [], // 出荷日回答待ち(purchaseStatus:5)
      answered: [], // 納品予約待ち(purchaseStatus:6)
      shipped: [], // 入荷待ち(purchaseStatus:7)
      arrivaled: [], // 入荷済み(purchaseStatus:7)
      canceled: [], // キャンセル(purchaseStatus:8)
    };
    let response = await Api.dispatchPost("/purchase/search", condition);

    if (response.data.contents.orders) {
      response.data.contents.orders.map(async (p) => {
        if (p.changeStatus == 2) {
          statedPurchases.unchangeApproved.push(p);
        } else {
          switch (p.purchaseStatus) {
            case 1:
              statedPurchases.unapproved.push(p);
              break;
            case 2:
              statedPurchases.rejected.push(p);
              break;
            case 3:
              statedPurchases.approved.push(p);
              break;
            case 4:
              statedPurchases.answered.push(p);
              break;
            case 5:
              statedPurchases.shipped.push(p);
              break;
            case 6:
              statedPurchases.arrivaled.push(p);
              break;
            case 7:
              statedPurchases.canceled.push(p);
              break;
          }
        }
      });
    }

    if (response.data.contents.purchases) {
      response.data.contents.purchases.map(async (p) => {
        RequestUtils.pushPurchase(p, statedPurchases);
      });
    }
    response.data.contents.statedPurchases = statedPurchases;

    return response;
  },
  async purchasEntrySearch(_, condition) {
    return Api.dispatchPost("/purchase/search-element", condition);
  },
  async entry(_, condition) {
    return Api.dispatchPost("/purchase/create", condition);
  },
  async entryBatch(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/purchase/create-batch", bulkModel, header);
    });
    return response;
  },
  async update(_, condition) {
    return Api.dispatchPost("/purchase/update", condition);
  },
  async bulkupdate(_, condition) {
    let response = RequestUtils.procBulkData(
      condition,
      (params) => {
        var payloadParam = {
          purchase: params.rows,
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/purchase/bulk-payload-bulkupdate", payloadParam);
      },
      (condition) => {
        let bulkModel = condition.contents;
        let header = condition.header;
        return Api.dispatchPost("/purchase/bulkupdate", bulkModel, header);
      }
    );
    return response;
  },
  async import(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/purchase/import", bulkModel, header);
    });
    return response;
  },
  async cancel(_, condition) {
    return Api.dispatchPost("/purchase/cancel", condition);
  },
  async print(_, condition) {
    return Api.dispatchPost("/purchase/out-ordersheet", condition);
  },
  async changeApproval(_, condition) {
    return Api.dispatchPost("/purchase/approval-change", condition);
  },
  async bulkPayloadApproval(_, importModel) {
    return Api.dispatchPost("/purchase/bulk-payload-approval", importModel);
  },
  async bulkPayloadShipdate(_, importModel) {
    return Api.dispatchPost("/purchase/bulk-payload-shipdate", importModel);
  },
  async bulkPayloadDeliveryreservation(_, importModel) {
    return Api.dispatchPost("/purchase/bulk-payload-deliveryreservation", importModel);
  },
  async approval(_, condition) {
    let model = condition.contents;
    let header = condition.header;
    return Api.dispatchPost("/purchase/approval", model, header);
  },
  async decision(_, condition) {
    return Api.dispatchPost("/purchase/decision", condition);
  },
  async answer(_, condition) {
    let model = condition.contents;
    let header = condition.header;
    return Api.dispatchPost("/purchase/set-shipdate", model, header);
  },
  async reserve(_, condition) {
    let model = condition.contents;
    let header = condition.header;
    return Api.dispatchPost("/purchase/set-deliveryreservation", model, header);
  },
  async approvalDirect(_, condition) {
    return Api.dispatchPost("/purchase/approval-direct", condition);
  },
  async orderSearch(_, condition) {
    return Api.dispatchPost("/orders/search", condition);
  },
  async searchStock(_, condition) {
    return Api.dispatchGet("/purchase/search-stock", condition);
  },
  async history(_, condition) {
    return Api.dispatchGet("/purchase/history", { purchaseId: condition });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
