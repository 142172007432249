import { createDefault, valueOf } from "../utils/ConstUtils";
import CorporationTypes from "../consts/CorporationTypes";

export default createDefault(
  [
    { value: "common", text: "common", type: 0, enum: "COMMON" },
    { value: "noauth", text: "noauth", type: 0, enum: "NOAUTH" },
    { value: "cafereo", text: "cafereo", type: CorporationTypes.CAFEREO, enum: "CAFEREO" },
    { value: "supplier", text: "supplier", type: CorporationTypes.MAKER, enum: "MAKER" },
    { value: "vendor", text: "vendor", type: CorporationTypes.VENDOR, enum: "VENDOR" },
  ],
  (constant, items) => {
    constant.fromType = (corporationType) => {
      return valueOf(items, corporationType, "type", "value");
    };
  }
);
