import security from "./security";
import password from "./password";
import ui from "./ui";
import user from "./user";
import filing from "./filing";
import validation from "./validation";
import corporation from "./corporation";
import department from "./department";
import cafereoUser from "./cafereoUser";
import product from "./product";
import productImage from "./productImage";
import notice from "./notice";
import order from "./order";
import purchase from "./purchase";
import orderReturn from "./orderReturn";
import deficiency from "./deficiency";
import purchaseReturn from "./purchaseReturn";
import proposition from "./proposition";
import stock from "./stock";
import conversion from "./conversion";
import loftCarton from "./loftCarton";
import deliveryDestination from "./deliveryDestination";
import yodobashi from "./yodobashi";
import billing from "./billing";
import invoice from "./invoice";
import payment from "./payment";
import supplier from "./supplier";
import customer from "./customer";
import amazon from "./amazon";
import trackingInformation from "./trackingInformation";
import temporaryPurchase from "./temporaryPurchase";
import replacement from "./replacement";
import corporationRate from "./corporationRate";
import insure from "./insure";
import shippingInstruction from "./shippingInstruction";
import arrivalAchievement from "./arrivalAchievement";
import shipmentAchievement from "./shipmentAchievement";
import propositionDetail from "./propositionDetail";
import category from "./category";
import forcast from "./forcast";
import transfer from "./transfer";
import arrivalPlan from "./arrivalPlan";
import stockMatch from "./stockMatch";
import history from "./history";
import dashboard from "./dashboard";
import deliveryArea from "./deliveryArea";

export default {
  security,
  password,
  ui,
  filing,
  user,
  validation,
  corporation,
  department,
  cafereoUser,
  product,
  productImage,
  notice,
  order,
  purchase,
  orderReturn,
  deficiency,
  purchaseReturn,
  proposition,
  stock,
  conversion,
  loftCarton,
  deliveryDestination,
  yodobashi,
  billing,
  invoice,
  payment,
  supplier,
  customer,
  amazon,
  trackingInformation,
  temporaryPurchase,
  replacement,
  corporationRate,
  insure,
  shippingInstruction,
  arrivalAchievement,
  shipmentAchievement,
  propositionDetail,
  category,
  forcast,
  transfer,
  arrivalPlan,
  stockMatch,
  history,
  dashboard,
  deliveryArea,
};
