<template>
  <v-menu v-model="menuOpend" :close-on-content-click="false" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        autocomplete="off"
        :value="formattedText"
        :label="label"
        v-bind="attrs"
        v-on="on"
        clearable
        append-icon="mdi-calendar"
        filled
        dense
        readonly
        :disabled="disabled"
        :rules="rules"
        @click:clear="onClearClicked"
        @click:append="menuOpend = true"
        :error-messages="errorMessages"
      ></v-text-field>
    </template>
    <v-date-picker
      no-title
      locale="ja"
      v-model="bindDate"
      :type="calendarType"
      :picker-date.sync="pickerDate"
      @input="onPickerChanged"
    >
      <div>
        <template v-if="type === 'about'">
          <v-btn-toggle v-model="dateSeason" color="primary">
            <v-btn
              small
              v-for="season in dateSeasons"
              :key="season.value"
              :value="season.value"
              v-text="season.text"
              @click="onSeasonChanged(season, $event)"
            ></v-btn>
          </v-btn-toggle>
        </template>
        <template v-else-if="type === 'datetime'">
          <vue-timepicker
            v-if="type === 'datetime'"
            format="HH:mm"
            v-model="bindTime"
            hour-label="時"
            minute-label="分"
            drop-direction="up"
            hide-clear-button
            fixed-dropdown-button
            auto-scroll
            :minute-interval="interval"
            @input="onTimeChanged"
          ></vue-timepicker>
          <v-btn color="primary" outlined absolute right small @click="menuOpend = false">OK</v-btn>
        </template>
      </div>
    </v-date-picker>
  </v-menu>
</template>

<style>
.v-date-picker-table {
  height: auto !important;
}
</style>

<script>
import moment from "moment";
import { ConstantLoader } from "../../../mixins/loader";
import DateSeasons from "../../../consts/DateSeasons";

export default {
  name: "DatePicker",
  mixins: [ConstantLoader({ DateSeasons })],
  model: { prop: "value", event: "change" },
  props: {
    type: { type: String, default: "date" },
    label: { type: String, default: "" },
    value: { type: String, default: null },
    interval: { type: Number },
    rules: { type: Array },
    hideAbout: { type: Array },
    disabled: { type: Boolean, default: false },
    errorMessages: { type: Array },
  },
  data: function () {
    return {
      menuOpend: false,
      calendarType: null,
      formattedText: null,
      vindText: null,
      pickerDate: null,
      bindDate: null,
      bindTime: "00:00",
      dateSeason: null,
    };
  },
  beforeMount() {
    this.clear();
    this.init();
  },
  watch: {
    type() {
      this.init();
    },
    value() {
      this.init();
    },
  },
  computed: {
    dateSeasons: function () {
      return DateSeasons.all().filter((dataSeason) => {
        return !(this.hideAbout && this.hideAbout.indexOf(dataSeason.value) !== -1);
      });
    },
  },
  methods: {
    init() {
      this.calendarType = this.type === "month" ? "month" : "date";
      if (this.value && this.value.length > 0) {
        if (this.value === this.formattedText) {
          return;
        }
        switch (this.type) {
          case "month":
            this.initOfMonth(this.value);
            break;
          case "about":
            this.initOfAbout(this.value);
            break;
          case "datetime":
            this.initOfDatetime(this.value);
            break;
          default:
            this.initOfDate(this.value);
            break;
        }
      } else {
        this.clear();
      }
    },
    initOfMonth(dateString) {
      const yyyymmdd = moment(dateString, "YYYY/MM");
      this.bindDate = yyyymmdd.format("YYYY-MM");
      this.formattedText = yyyymmdd.format("YYYY/MM");
      this.vindText = yyyymmdd.format("YYYY-MM");
    },
    initOfAbout(dateString) {
      const matches = /^(?<y>[0-9]{4}).(?<m>0[1-9]|1[0-2]).(?<d>[^0-9]+)$/.exec(dateString);
      if (matches != null && matches.length === 4) {
        const season = DateSeasons.all().find((s) => s.text === matches.groups.d || s.short === matches.groups.d);
        if (season) {
          this.dateSeason = season.value;
          this.pickerDate = `${matches.groups.y}-${matches.groups.m}`;
          this.formattedText = `${matches.groups.y}/${matches.groups.m}/${matches.groups.d}`;
          this.vindText = this.formattedText;
        } else {
          this.clear();
        }
      } else {
        if (dateString === "未定") {
          const season = DateSeasons.all().find((s) => s.text === dateString || s.short === dateString);
          this.dateSeason = season.value;
          this.formattedText = dateString;
          this.vindText = this.formattedText;
        } else {
          this.initOfDate(dateString);
        }
      }
    },
    initOfDatetime(dateString) {
      const datetime = moment(dateString, "YYYY/MM/DD HH:mm:ss");
      this.bindDate = datetime.format("YYYY-MM-DD");
      this.bindTime = datetime.format("HH:mm");
      this.formattedText = `${datetime.format("YYYY/MM/DD")} ${this.bindTime}`;
      this.vindText = `${datetime.format("YYYY-MM-DD")} ${this.bindTime}`;
    },
    initOfDate(dateString) {
      const yyyymmdd = moment(dateString, "YYYY/MM/DD");
      this.bindDate = yyyymmdd.format("YYYY-MM-DD");
      this.formattedText = yyyymmdd.format("YYYY/MM/DD");
      this.vindText = yyyymmdd.format("YYYY-MM-DD");
      if (this.type === "about") {
        this.pickerDate = yyyymmdd.format("YYYY-MM");
      }
    },
    clear() {
      this.bindDate = null;
      this.bindTime = "00:00";
      this.formattedText = null;
      this.vindText = null;
      this.dateSeason = null;
      this.cleanPickerDate();
    },
    cleanPickerDate(force) {
      if (this.type == "about") {
        if (!this.pickerDate || force) {
          this.pickerDate = moment().format("YYYY-MM");
        }
      } else {
        this.pickerDate = null;
      }
    },
    emitInput() {
      this.$emit("change", this.vindText);
    },
    onClearClicked() {
      this.clear();
      this.emitInput();
    },
    onPickerChanged(e) {
      this.dateSeason = null;
      if (this.type === "month") {
        this.formattedText = moment(e, "YYYY/MM").format("YYYY/MM");
        this.vindText = moment(e, "YYYY/MM").format("YYYY-MM");
      } else {
        const yyyymmdd = moment(e, "YYYY/MM/DD").format("YYYY/MM/DD");
        const yyyymmddvind = moment(e, "YYYY/MM/DD").format("YYYY-MM-DD");
        if (this.type === "datetime") {
          const hhmm = this.bindTime || "00:00";
          this.formattedText = `${yyyymmdd} ${hhmm}`;
          this.vindText = `${yyyymmddvind} ${hhmm}`;
        } else {
          this.formattedText = yyyymmdd;
          if (this.type === "about") {
            this.vindText = yyyymmdd;
          } else {
            this.vindText = yyyymmddvind;
          }
        }
      }
      if (this.type !== "datetime") {
        this.menuOpend = false;
      }
      this.emitInput();
    },
    onTimeChanged(e) {
      if (this.bindDate) {
        const yyyymmdd = moment(this.bindDate, "YYYY/MM/DD").format("YYYY/MM/DD");
        const yyyymmddvind = moment(this.bindDate, "YYYY/MM/DD").format("YYYY-MM-DD");
        const hhmm = e || "00:00";
        this.formattedText = `${yyyymmdd} ${hhmm}`;
        this.vindText = `${yyyymmddvind} ${hhmm}`;
        this.emitInput();
      }
    },
    onSeasonChanged(season) {
      this.cleanPickerDate(false);
      const text = DateSeasons.of(season.value);
      if (season.value === "pending") {
        this.formattedText = `${text}`;
      } else {
        const [y, m] = this.pickerDate.split("-");
        this.formattedText = `${y}/${m}/${season.short}`;
      }
      this.vindText = this.formattedText;
      this.menuOpend = false;
      this.emitInput();
    },
  },
};
</script>
