<template>
  <v-card class="overflow-y-auto" max-height="250" min-height="250">
    <v-card-title
      ><v-icon>mdi-book-information-variant</v-icon>{{ isCafereoUser ? "ユーザー情報" : "弊社担当" }}</v-card-title
    >
    <v-card-text>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>氏名</v-list-item-title>
            <v-list-item-subtitle>{{ userInfo ? userInfo.userName : "" }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>部署</v-list-item-title>
            <v-list-item-subtitle>{{ userInfo ? userInfo.affiliation : "" }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>連絡先</v-list-item-title>
            <template>
              <v-list-item-subtitle>{{ userInfo ? userInfo.telNo : "" }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ userInfo ? userInfo.mail : "" }}</v-list-item-subtitle>
            </template>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SalesStaff",
  props: ["userInfo"],
};
</script>
