import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/amazon/search-po", condition);
  },
  async import(_, condition) {
    let header = condition.header;
    return Api.dispatchPost("/amazon/import-po", {}, header);
  },
  async hycon(_, updateModel) {
    return Api.dispatchPost("/amazon/hycon", updateModel);
  },
  async update(_, condition) {
    return Api.dispatchPost("/amazon/update-po", condition);
  },
  async comfirm(_, condition) {
    let comfirmModel = condition.contents;
    let header = condition.header;
    return Api.dispatchPost("/amazon/comfirm-po", comfirmModel, header);
  },
  async bulkComfirm(_, condition) {
    return Api.dispatchPost("/amazon/bulk-comfirm-po", condition);
  },
  async searchShippinginstruct(_, condition) {
    return Api.dispatchGet("/amazon/search-shippinginstruct", condition);
  },
  async searchPacking() {
    return Api.dispatchGet("/amazon/search-packing", null);
  },
  async referencePacking(_, condition) {
    return Api.dispatchGet("/amazon/reference-packing", condition);
  },
  async cancelPacking(_, condition) {
    return Api.dispatchPost("/amazon/cancel-packing", condition);
  },
  async comfirmPacking(_, condition) {
    return Api.dispatchPost("/amazon/comfirm-packing", condition);
  },
  async sscc(_, condition) {
    return Api.dispatchPost("/amazon/sscc", condition);
  },
  async removeSscc(_, condition) {
    return Api.dispatchPost("/amazon/remove-sscc", condition);
  },
  async searchNotification() {
    return Api.dispatchGet("/amazon/search-notification", null);
  },
  async comfirmNofitication(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/amazon/comfirm-notification", bulkModel, header);
    });
    return response;
  },
  async outShippinginstruct(_, condition) {
    return Api.dispatchPost("/shippinginstruct/out-shippinginstruct", condition);
  },
  async searchReservation(_, condition) {
    return Api.dispatchGet("/amazon/search-reservation", condition);
  },
  async outReservationlist(_, condition) {
    return Api.dispatchPost("/amazon/out-reservationlist", condition);
  },
  async issueRbs(_, condition) {
    return Api.dispatchPost("/amazon/issue-rbs", condition);
  },
  async importProductlist(_, condition) {
    return Api.dispatchPost("/amazon/import-productlist", condition);
  },
  async searchIssueAsin(_, condition) {
    return Api.dispatchPost("/amazon/search-issueAsin", condition);
  },
  async importAsin(_, condition) {
    return Api.dispatchPost("/amazon/import-asin", condition);
  },
  async requestAsin(_, condition) {
    return Api.dispatchPost("/amazon/request-asin", condition);
  },
  async searchResaleNyp(_, condition) {
    return Api.dispatchPost("/amazon/search-resaleNyp", condition);
  },
  async sendResaleNyp(_, condition) {
    return Api.dispatchPost("/amazon/send-resale-nyp", condition);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
