import * as Api from "./../../libs/api-client";

const actions = {
  async search(_, condition) {
    return Api.dispatchGet("/payment/search", condition);
  },
  async update(_, updatekModel) {
    return Api.dispatchPost("/payment/update", updatekModel);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
