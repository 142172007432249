// https://dexie.org/docs/API-Reference#quick-reference
import Dexie from "dexie";

const DB_NAME = "cafereo_db";
const db = new Dexie(DB_NAME);

// テーブル名の先頭は命名規約に則ること
// m: MASTER
// t: TRANSACTION
// w: WORK/TEMP
// c: COMMON
// h: HISTROTY
// r: REPORT
// v: VIEW

// ※※※スキーマを追加・修正する場合は、前のバージョンを残したまま、ロジックを追加してください。※※※
const versionedSchemas = [
  {
    c_sequences: "++rowId,&name",
    m_corporations: "++rowId,&corporationCode,corporationName",
  },
  {
    m_users: "++rowId,&userId,userName",
    m_customers: "++rowId,&customerCode,customerName",
    m_suppliers: "++rowId,&supplierCode,supplierName",
    m_deliveries: "++rowId,&deliveryCode,deliveryName",
  },
  {
    t_products: "++rowId,&productCode,janCode",
  },
  {
    t_orders: "++rowId,&orderId",
  },
  {
    t_stocks: "++rowId,&productCode,storageCode",
    m_storages: "++rowId,&storageCode",
  },
  {
    m_corporation_rates: "++rowId,corporationCode,productCode",
  },
  {
    t_purchases: "++rowId,&purchaseId",
  },
  {
    t_stocks: "++rowId,productCode,storageCode",
  },
  {
    m_conversions: "++rowId,conversionCode",
  },
  {
    t_purchase_returns: "++rowId,&returnCommitNumber",
  },
  {
    t_shipping_instructions: "++rowId,&shippingInstructionId",
  },
  {
    t_arrival_achievements: "++rowId,&arrivalId",
  },
  {
    t_shipment_achievements: "++rowId,&shipmentAchievementId",
  },
  {
    t_excess_deficiency: "++rowId,&excessDeficiencyId",
  },
  {
    t_order_returns: "++rowId,&orderReturnId",
    t_order_replacements: "++rowId,&orderReplacementId,&orderReturnId",
  },
  {
    t_billings: "++rowId,&billingNumber",
    t_invoices: "++rowId,&invoiceNumber",
  },
  {
    t_propositions: "++rowId,&propositionId",
    t_proposition_details: "++rowId,&propositionDetailId",
  },

  // ※※※この行の^^^前^^^に定義を追加すること※※※
];

let versionNo = 0;
let oldVersionSchema = {};
versionedSchemas.forEach((schemaDefine) => {
  const newSchema = { ...oldVersionSchema, ...schemaDefine };
  db.version(++versionNo).stores(newSchema);
  oldVersionSchema = newSchema;
  // console.debug("[localdb]", versionNo, newSchema);
});

console.info("[localdb]", db.name, db.verno);
export default db;
