import * as Api from "./../../libs/api-client";

const actions = {
  async search(_, condition) {
    return Api.dispatchGet("/user/search", condition);
  },
  async create(_, userModel) {
    return Api.dispatchPost("/user/create", userModel);
  },
  async update(_, userModel) {
    return Api.dispatchPost("/user/update", userModel);
  },
  async remove(_, users) {
    return Api.dispatchPost("/user/delete", users);
  },
  async history(_, condition) {
    return Api.dispatchGet("/user/history", { userId: condition });
  },
  async loginUser(_, condition) {
    return Api.commonGet("/user/login-user", condition);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
