const readAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
};

const readAsArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsArrayBuffer(file);
  });
};

const readAsText = (file, sjis) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsText(file, sjis ? "Shift_JIS" : undefined);
  });
};

const readAsCsv = async (file, sjis) => {
  const csvText = await readAsText(file, sjis);
  return csvText.split("\n").map((line) => line.split(",").map((filed) => filed.trim()));
};

const readAsCsvSpecQuote = async (file, sjis) => {
  const csvText = await readAsText(file, sjis);
  return parseCsv(csvText);
};
const readAsCsvUtf = async (file) => {
  let csvText = await readAsText(file, false);
  return parseCsv(csvText);
};
const parseCsv = async (csv) =>
  csv
    .replace(/\r/g, "")
    .split("\n")
    .reduce(
      ([data, isInQuotes], line) => {
        const [datum, newIsInQuotes] = ((isInQuotes ? '"' : "") + line).split(",").reduce(
          ([datum, isInQuotes], text) => {
            const match = isInQuotes || text.match(/^("?)((.*?)("*))$/);
            if (isInQuotes) datum[datum.length - 1] += "," + text.replace(/"+/g, (m) => '"'.repeat(m.length / 2));
            else datum.push(match[1] ? match[2].replace(/"+/g, (m) => '"'.repeat(m.length / 2)) : match[2]);
            return [datum, isInQuotes ? !(text.match(/"*$/)[0].length % 2) : match[1] && !(match[4].length % 2)];
          },
          [[]]
        );
        if (isInQuotes) data[data.length - 1].push(data[data.length - 1].pop() + "\n" + datum[0], ...datum.slice(1));
        else data.push(datum);
        return [data, newIsInQuotes];
      },
      [[]]
    )[0];

const getImageSize = async (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const size = [img.naturalWidth, img.naturalHeight];
      URL.revokeObjectURL(img.src);
      resolve(size);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = URL.createObjectURL(file);
  });
};

// const downloadFile = (url, fileName) => {
const downloadFile = (url) => {
  // const anchor = window.document.createElement("a");
  // anchor.download = fileName;
  // anchor.href = url;
  // anchor.click();
  window.open(url, "_blank");
};

const toNumericColIndex = (string_col_index) => {
  var RADIX = 26;
  var A = "A".charCodeAt(0);
  var s = string_col_index.toUpperCase();
  var n = 0;
  for (var i = 0, len = s.length; i < len; i++) {
    n = n * RADIX + (s.charCodeAt(i) - A + 1);
  }
  return n;
};
const toStringColIndex = (numeric_col_index) => {
  var RADIX = 26;
  var A = "A".charCodeAt(0);
  var n = numeric_col_index;
  var s = "";
  while (n >= 1) {
    n--;
    s = String.fromCharCode(A + (n % RADIX)) + s;
    n = Math.floor(n / RADIX);
  }
  return s;
};

const readAsCsvReplaceDoubleQuote = async (file, sjis) => {
  const csvText = await readAsText(file, sjis);
  return csvText
    .replace(/"/g, "")
    .split("\n")
    .map((line) => line.split(",").map((filed) => filed.trim()));
};

const importHelper = (importFileFomat) => {
  const constant = {};
  constant.headerCnt = importFileFomat.headerCnt;
  constant.details = importFileFomat.details;
  constant.quotes = importFileFomat.quotes;
  constant.conversionFlg = importFileFomat.conversionFlg;
  constant.findIndexByFieldName = (fieldName) => {
    return constant.details.findIndex((elm) => elm.fieldName === fieldName);
  };
  Object.freeze(constant);
  return constant;
};

export default {
  readAsDataURL,
  readAsArrayBuffer,
  readAsText,
  readAsCsv,
  readAsCsvUtf,
  readAsCsvSpecQuote,
  parseCsv,
  getImageSize,
  downloadFile,
  toNumericColIndex,
  toStringColIndex,
  readAsCsvReplaceDoubleQuote,
  importHelper,
};
