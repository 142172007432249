import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/insurefix/search", condition);
  },
  async fix(_, condition) {
    let response = RequestUtils.procBulkData(
      condition,
      (params) => {
        var payloadParam = {
          orders: params.rows,
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/insurefix/confirm-bulk-payload", payloadParam);
      },
      (condition) => {
        let bulkModel = condition.contents;
        let header = condition.header;
        return Api.dispatchPost("/insurefix/confirm", bulkModel, header);
      }
    );
    return response;
  },
  async fixCancel(_, condition) {
    return Api.dispatchPost("/insurefix/cancel", condition);
  },
  async autoFix(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/insurefix/auto-confirm", bulkModel, header);
    });
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
