const state = {
  // TODO: モック用
  trackingInformations: [
    {
      deliveryInvoiceIssuanceDate: "20191001",
      deliveryCode: "1200000",
      customerName: "株式会社アニメイト",
      companyStoreCode: "120002",
      storeName: "アニメイト京都店",
      deliveryInvoiceNumber: "405509711224",
      deliveryName1: "",
      deliveryAddress: "京都府京都市中京区新京極蛸薬師下ル東側町５２５-１パッサージオ（吉本ビル）２Ｆ",
      originalNumber: "",
      deliveryName2: "",
      orderNumber: "000000000000001",
      updateDatetime: "田中",
      updateUser: "2021-06-23",
      createDatetime: "田中",
      createUser: "2021-06-23",
    },
  ],
};

const getters = {
  // TODO: モック用
  all(state) {
    return JSON.parse(JSON.stringify(state.trackingInformations));
  },
};

const mutations = {};

const actions = {
  search({ getters }, conditions) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const f1 = conditions && conditions.deliveryInvoiceNumber ? (order) => order.deliveryInvoiceNumber : () => true;
        resolve({ records: getters.all.filter((order) => f1(order)) });
      }, 1200);
    });
  },
  // entry({ state }, user) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       const seq = state.users.filter((u) => u.corpcode === user.corpcode).length + 1;
  //       const num = String(seq).padStart(4, "0");
  //       const model = {
  //         userid: `${user.corpcode}-${num}`,
  //         corpcode: user.corpcode,
  //         shopcode: user.shopcode,
  //         username: user.username,
  //         email: user.email,
  //         role: user.role,
  //         lock: user.lock,
  //         inactive: user.inactive,
  //       };
  //       state.users.push(model);
  //       resolve(model);
  //     }, 1200);
  //   });
  // },
  // update({ state }, user) {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       const index = state.users.findIndex((u) => u.userid === user.userid);
  //       if (index === -1) {
  //         reject();
  //       } else {
  //         state.users[index].shopcode = user.shopcode;
  //         state.users[index].username = user.username;
  //         state.users[index].email = user.email;
  //         state.users[index].role = user.role;
  //         state.users[index].lock = user.lock;
  //         state.users[index].inactive = user.inactive;
  //         resolve();
  //       }
  //     }, 300);
  //   });
  // },
  // remove({ state }, userid) {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       const index = state.users.findIndex((u) => u.userid === userid);
  //       if (index === -1) {
  //         reject();
  //       } else {
  //         state.users[index].removed = true;
  //         resolve();
  //       }
  //     }, 300);
  //   });
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
