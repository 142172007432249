//import moment from "moment";
import * as Api from "./../../libs/api-client";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/productImage/search", condition);
  },
  async getProductImageTree(_, condition) {
    return Api.dispatchGet("/productImage/get-product-image-tree", condition);
  },
  async entry(_, productModel) {
    console.log(productModel);
    return Api.dispatchPost("/productImage/create", productModel);
  },
  async update(_, productModel) {
    console.log(productModel);
    return Api.dispatchPost("/productImage/update", productModel);
  },
  async download(_, condition) {
    return Api.dispatchPost("/productImage/download", condition);
  },
  async delete(_, condition) {
    return Api.dispatchPost("/productImage/delete", condition);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
