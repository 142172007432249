import * as Api from "./../../libs/api-client";

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchPost(`/yodobashi/search-productcomplete`, condition);
  },
  async create(_, condition) {
    return Api.dispatchPost("/yodobashi/out-productcomplete", condition);
  },
  async outShippingData(_, condition) {
    return Api.dispatchPost("/yodobashi/out-shippingdata", condition);
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
};
