import * as Api from "./../../libs/api-client";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async history(_, condition) {
    return Api.dispatchGet("/orderreturn/history", { orderReturnId: condition });
  },
  async search(_, condition) {
    return Api.dispatchPost("/orderreturn/search", condition);
  },
  async orderSearch(_, condition) {
    return Api.dispatchPost("/orders/search", condition);
  },
  async entry(_, condition) {
    return Api.dispatchPost("/orderreturn/create", condition);
  },
  async update(_, condition) {
    return Api.dispatchPost("/orderreturn/update", condition);
  },
  async cancel(_, condition) {
    return Api.dispatchPost("/orderreturn/cancel", condition);
  },
  async approval(_, condition) {
    return Api.dispatchPost("/orderreturn/approval", condition);
  },
  async reject(_, condition) {
    return Api.dispatchPost("/orderreturn/reject", condition);
  },
  async print(_, condition) {
    return Api.dispatchPost("/orderreturn/out-delivery-note", condition);
  },
  async printReturn(_, condition) {
    return Api.dispatchPost("/orderreturn/out-return-receipt", condition);
  },
  async bulkupdate(_, condition) {
    return Api.dispatchPost("/orderreturn/bulkupdate", condition);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
