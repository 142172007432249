import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async history(_, condition) {
    return Api.dispatchGet("/propositiondetail/history", { propositionDetailId: condition });
  },
  async search(_, condition) {
    return Api.dispatchGet("/propositiondetail/search", condition);
  },
  async entry(_, condition) {
    return Api.dispatchPost("/propositiondetail/create", condition);
  },
  async import(_, condition) {
    const response = RequestUtils.procBulkData(
      condition,
      (params) => {
        const payloadParam = {
          propositionDetails: params.rows,
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/propositiondetail/bulk-payload", payloadParam);
      },
      (condition) => {
        const contents = condition.contents;
        const header = condition.header;
        return Api.dispatchPost("/propositiondetail/import", contents, header);
      }
    );
    return response;
  },
  async update(_, condition) {
    return Api.dispatchPost("/propositiondetail/update", condition);
  },
  async remove(_, condition) {
    return Api.dispatchPost("/propositiondetail/delete", condition);
  },
  async resultEntry(_, condition) {
    return Api.dispatchPost("/propositiondetail/set-result", condition);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
