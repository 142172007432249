import { statuses as ApiStatus } from "../libs/api-client";
import * as Api from "./../libs/api-client";
import VueStore from "../store/index";

const sliceByNumber = (array, number) => {
  const length = Math.ceil(array.length / number);
  return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
};

const procBulkData = async (condition, beforeFunc, mainFunc) => {
  console.log("start procBulkData");
  console.log(beforeFunc);
  let requestRecordsSplit = sliceByNumber(condition.rows, condition.splitNum);
  let bulkId = null;
  // APIでマージして引数を保持
  for (let i = 0; i < requestRecordsSplit.length; i++) {
    var payloadParam = {
      rows: requestRecordsSplit[i],
      bulkId: bulkId,
    };
    console.log(payloadParam);
    const response = await beforeFunc(payloadParam);
    let error = response.data?.header;
    switch (error.resultCode) {
      case ApiStatus.consts.SUCCESS:
        console.log(response.data.contents);
        bulkId = response.data.contents.bulkId;
        break;
      default:
        return response;
    }
  }
  let headers = null;
  var param = {
    bulkId: bulkId,
  };
  let mainModel = {
    contents: param,
    header: headers,
  };
  let header = condition.header;
  let response = await mainFunc(mainModel, header);
  while (response.data.header.resultCode === ApiStatus.consts.PROCESSING) {
    mainModel.header = { InvokeId: response.data.header.invokeId };
    await new Promise((resolve) => setTimeout(resolve, 1000));
    response = await mainFunc(mainModel, headers);
  }
  return response;
};

// procBulkDataと比べ前処理となるデータアップロードを共通化したもの
const procBulkData2 = async (condition, mainFunc) => {
  console.log("start procBulkData2");
  const SLICE_SIZE = 10240;
  let conditionStr = JSON.stringify(condition);
  let cndStrList = [];
  for (let i = 0; i < conditionStr.length; i += SLICE_SIZE) {
    cndStrList.push(conditionStr.slice(i, i + SLICE_SIZE));
  }
  let bulkId = null;
  // APIでマージして引数を保持
  for (let i = 0; i < cndStrList.length; i++) {
    var payloadParam = {
      payloadParts: cndStrList[i],
      bulkId: bulkId,
    };
    const response = await Api.commonPost("/data/bulk-payload", payloadParam);
    let error = response.data?.header;
    switch (error.resultCode) {
      case ApiStatus.consts.SUCCESS:
        console.log(response.data.contents);
        bulkId = response.data.contents.bulkId;
        break;
      default:
        return response;
    }
  }
  let headers = null;
  var param = {
    bulkId: bulkId,
  };
  let mainModel = {
    contents: param,
    header: headers,
  };
  let header = condition.header;
  let response = await mainFunc(mainModel, header);
  while (response.data.header.resultCode === ApiStatus.consts.PROCESSING) {
    mainModel.header = { InvokeId: response.data.header.invokeId };
    await new Promise((resolve) => setTimeout(resolve, 1000));
    response = await mainFunc(mainModel, headers);
  }
  return response;
};

const validDataSize = async (functionId, dataCount, errorFunc) => {
  let ret = true;
  const validResponse = await VueStore.dispatch("validation/dataCount", {
    functionId: functionId,
    dataCount: dataCount,
  });
  switch (validResponse.data?.header.resultCode) {
    case ApiStatus.consts.SUCCESS:
      // 結果が-1はチェックOK
      if (validResponse.data.contents > -1) {
        errorFunc(validResponse.data.contents);
        ret = false;
      }
      break;
    default:
      throw new Error("validDataSize request error.");
  }
  return ret;
};

const pushPurchase = (row, statedPurchases) => {
  if (row.changeStatus == 2) {
    statedPurchases.unchangeApproved.push(row);
  } else {
    switch (row.purchaseStatus) {
      case 1:
        statedPurchases.unsanction.push(row);
        break;
      case 2:
        statedPurchases.sanctionRejected.push(row);
        break;
      case 3:
        statedPurchases.unapproved.push(row);
        break;
      case 4:
        statedPurchases.rejected.push(row);
        break;
      case 5:
        statedPurchases.approved.push(row);
        break;
      case 6:
        statedPurchases.answered.push(row);
        break;
      case 7:
        statedPurchases.shipped.push(row);
        break;
      case 8:
        statedPurchases.arrivaled.push(row);
        break;
      case 9:
        statedPurchases.canceled.push(row);
        break;
    }
  }
  return statedPurchases;
};

const pushPurchaseReturn = (row, statedReturns) => {
  switch (row.returnCommitStatus) {
    case 1:
      statedReturns.unapproved.push(row);
      break;
    case 2:
      statedReturns.notshipped.push(row);
      break;
    case 3:
      statedReturns.uninspected.push(row);
      break;
    case 4:
      if (row.productConditionType == 1 || row.productConditionType == 2) statedReturns.inspected.push(row);
      break;
    case 5:
      statedReturns.cancel.push(row);
      break;
  }
  return statedReturns;
};

export default {
  procBulkData,
  procBulkData2,
  validDataSize,
  pushPurchase,
  pushPurchaseReturn,
};
