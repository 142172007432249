<template>
  <v-list dense>
    <v-list-item exact :to="{ name: 'Dashboard' }">
      <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
      <v-list-item-title>ダッシュボード</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-toy-brick" v-if="allowedAction(['C010101', 'C010201'])">
      <template v-slot:activator>
        <v-list-item-title>登録商品管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'ProductRegisteredSearch' }" v-if="allowedAction(['C010101'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>登録商品一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ProductImageRegisteredSearch' }" v-if="allowedAction(['C010201'])">
        <v-list-item-icon><v-icon>mdi-image</v-icon></v-list-item-icon>
        <v-list-item-title>登録商品画像一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ProductImageEntry' }" v-if="allowedAction(['C010301'])">
        <v-list-item-icon><v-icon>mdi-image</v-icon></v-list-item-icon>
        <v-list-item-title>新商品画像登録</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group
      no-action
      prepend-icon="mdi-book-open"
      v-if="allowedAction(['C020101', 'C020201', 'C020301', 'C020401', 'C020501'])"
    >
      <template v-slot:activator>
        <v-list-item-title>営業管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'OrderSearch' }" v-if="allowedAction(['C020101'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>受注一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'OrderAchievementSearch' }" v-if="allowedAction(['C020201'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>出荷一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ForecastSearch' }" v-if="allowedAction(['C020301'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>フォーキャスト管理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'PropositionSearch' }" v-if="allowedAction(['C020401'])">
        <v-list-item-icon><v-icon>mdi-folder-search</v-icon></v-list-item-icon>
        <v-list-item-title>委託・消化商品管理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'OrderReturnSearch' }" v-if="allowedAction(['C020501'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>返品処理</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group
      no-action
      prepend-icon="mdi-note"
      v-if="allowedAction(['C030101', 'C030201', 'C030301', 'C030401', 'C030501'])"
    >
      <template v-slot:activator>
        <v-list-item-title>仕入管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'ProductSearch' }" v-if="allowedAction(['C030101'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>商品登録</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'PurchaseEntry' }" v-if="allowedAction(['C030201'])">
        <v-list-item-icon><v-icon>mdi-note-plus</v-icon></v-list-item-icon>
        <v-list-item-title>発注管理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'PurchaseSearch' }" v-if="allowedAction(['C030301'])">
        <v-list-item-icon><v-icon>mdi-note-search</v-icon></v-list-item-icon>
        <v-list-item-title>発注一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'CafereoDeficiencySearch' }" v-if="allowedAction(['C030401'])">
        <v-list-item-icon><v-icon>mdi-note-search</v-icon></v-list-item-icon>
        <v-list-item-title>不足商品管理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'PurchaseReturnSearch' }" v-if="allowedAction(['C030501'])">
        <v-list-item-icon><v-icon>mdi-note-search</v-icon></v-list-item-icon>
        <v-list-item-title>メーカー返品一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group
      no-action
      prepend-icon="mdi-database"
      v-if="allowedAction(['C040101', 'C040201', 'C040301', 'C040401', 'C040501', 'C040601', 'C040701', 'C040801'])"
    >
      <template v-slot:activator>
        <v-list-item-title>在庫管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'StockSearch' }" v-if="allowedAction(['C040101'])">
        <v-list-item-icon><v-icon>mdi-database-search</v-icon></v-list-item-icon>
        <v-list-item-title>在庫表</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'DeliveryScheduleSearch' }" v-if="allowedAction(['C040201'])">
        <v-list-item-icon><v-icon>mdi-calendar-search</v-icon></v-list-item-icon>
        <v-list-item-title>入荷予定一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ArrivalAchievementTodaySearch' }" v-if="allowedAction(['C040301'])">
        <v-list-item-icon><v-icon>mdi-clipboard-text-search</v-icon></v-list-item-icon>
        <v-list-item-title>当日入荷実績一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ArrivalAchievementSearch' }" v-if="allowedAction(['C040401'])">
        <v-list-item-icon><v-icon>mdi-clipboard-text-search</v-icon></v-list-item-icon>
        <v-list-item-title>入荷実績一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'InsureFix' }" v-if="allowedAction(['C040501'])">
        <v-list-item-icon><v-icon>mdi-database-lock</v-icon></v-list-item-icon>
        <v-list-item-title>確保処理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ShippingInstructionSearch' }" v-if="allowedAction(['C040601'])">
        <v-list-item-icon><v-icon>mdi-truck</v-icon></v-list-item-icon>
        <v-list-item-title>出荷指示一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ShipmentAchievementSearch' }" v-if="allowedAction(['C040701'])">
        <v-list-item-icon><v-icon>mdi-clipboard-text-search</v-icon></v-list-item-icon>
        <v-list-item-title>出荷一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'FTStockMatching' }" v-if="allowedAction(['C040801'])">
        <v-list-item-icon><v-icon>mdi-compare</v-icon></v-list-item-icon>
        <v-list-item-title>FT在庫突合</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
    <v-list-group
      no-action
      prepend-icon="mdi-amazon"
      v-if="allowedAction(['C080101', 'C080201', 'C080301', 'C080401', 'C080501'])"
    >
      <template v-slot:activator>
        <v-list-item-title>Amazon専用</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'AmazonReservationSearch' }" v-if="allowedAction(['C080101'])">
        <v-list-item-icon><v-icon>mdi-format-list-bulleted-square</v-icon></v-list-item-icon>
        <v-list-item-title>予約情報管理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'AmazonPurchaseOrderSearch' }" v-if="allowedAction(['C080201'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>PO管理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'AmazonCartonInstruction' }" v-if="allowedAction(['C080301'])">
        <v-list-item-icon><v-icon>mdi-archive</v-icon></v-list-item-icon>
        <v-list-item-title>梱包指示</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'AmazonShippingInstruction' }" v-if="allowedAction(['C080401'])">
        <v-list-item-icon><v-icon>mdi-truck</v-icon></v-list-item-icon>
        <v-list-item-title>出荷指示</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'AmazonShippingNotification' }" v-if="allowedAction(['C080501'])">
        <v-list-item-icon><v-icon>mdi-bullhorn</v-icon></v-list-item-icon>
        <v-list-item-title>出荷通知</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group no-action prepend-icon="mdi-domain" v-if="allowedAction(['C090101', 'C090201'])">
      <template v-slot:activator>
        <v-list-item-title>その他EDI</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'ProductionCompletedApplication' }" v-if="allowedAction(['C090101'])">
        <v-list-item-icon><v-icon>mdi-text-box-check</v-icon></v-list-item-icon>
        <v-list-item-title>生産完了申請</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'LoftCartonSearch' }" v-if="allowedAction(['C090201'])">
        <v-list-item-icon><v-icon>mdi-database-search</v-icon></v-list-item-icon>
        <v-list-item-title>ロフトカートン予測一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-bank" v-if="allowedAction(['C050101', 'C050201', 'C050301'])">
      <template v-slot:activator>
        <v-list-item-title>請求・支払管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'BillingSearch' }" v-if="allowedAction(['C050101'])">
        <v-list-item-icon><v-icon>mdi-database-search</v-icon></v-list-item-icon>
        <v-list-item-title>請求データ一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'InvoiceSearch' }" v-if="allowedAction(['C050201'])">
        <v-list-item-icon><v-icon>mdi-text-box-check</v-icon></v-list-item-icon>
        <v-list-item-title>販売請求書一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'PaymentSearch' }" v-if="allowedAction(['C050301'])">
        <v-list-item-icon><v-icon>mdi-database-search</v-icon></v-list-item-icon>
        <v-list-item-title>仕入支払予定一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-account" v-if="allowedAction(['C060101', 'C060201', 'C060301'])">
      <template v-slot:activator>
        <v-list-item-title>ユーザ管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'UserSearch' }" v-if="allowedAction(['C060101'])">
        <v-list-item-icon><v-icon>mdi-account-search</v-icon></v-list-item-icon>
        <v-list-item-title>Café Reoユーザ一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'MakerUserSearch' }" v-if="allowedAction(['C060201'])">
        <v-list-item-icon><v-icon>mdi-account-search</v-icon></v-list-item-icon>
        <v-list-item-title>仕入先ユーザ一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'VendorUserSearch' }" v-if="allowedAction(['C060301'])">
        <v-list-item-icon><v-icon>mdi-account-search</v-icon></v-list-item-icon>
        <v-list-item-title>販売店ユーザ一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group
      no-action
      prepend-icon="mdi-handshake"
      v-if="allowedAction(['C070101', 'C070201', 'C070301', 'C070401', 'C070501'])"
    >
      <template v-slot:activator>
        <v-list-item-title>取引先管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'ConversionSearch' }" v-if="allowedAction(['C070101'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>取引先変換一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'CorporationSearch' }" v-if="allowedAction(['C070201'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>法人一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'CustomerSearch' }" v-if="allowedAction(['C070301'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>取引先一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'DeliveryDestinationSearch' }" v-if="allowedAction(['C070401'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>配送先一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'SupplierSearch' }" v-if="allowedAction(['C070501'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>仕入先一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
  </v-list>
</template>

<script>
export default {
  name: "CafereoMenu",
};
</script>
