<template>
  <div>
    <cafereo-dashboard
      v-if="isCafereoUser"
      :userInfo="userInfo"
      :banners="banners"
      :seriousNotifications="seriousNotifications"
      :systemInfos="systemInfos"
    ></cafereo-dashboard>
    <ftrans-dashboard
      v-else-if="isFtransUser"
      :userInfo="userInfo"
      :banners="banners"
      :seriousNotifications="seriousNotifications"
    ></ftrans-dashboard>
    <maker-dashboard
      v-else-if="isMakerUser"
      :userInfo="userInfo"
      :banners="banners"
      :seriousNotifications="seriousNotifications"
    ></maker-dashboard>
    <vendor-dashboard
      v-else-if="isVendorUser"
      :userInfo="userInfo"
      :banners="banners"
      :seriousNotifications="seriousNotifications"
    ></vendor-dashboard>
  </div>
</template>

<script>
import CafereoDashboard from "../../components/dashboard/CafereoDashboard.vue";
import FtransDashboard from "../../components/dashboard/FtransDashboard.vue";
import MakerDashboard from "../../components/dashboard/MakerDashboard.vue";
import VendorDashboard from "../../components/dashboard/VendorDashboard.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import ProcStatus from "../../consts/ProcStatus";

export default {
  name: "Dashboard",
  components: {
    CafereoDashboard,
    FtransDashboard,
    MakerDashboard,
    VendorDashboard,
  },
  data() {
    return {
      dashboardInfo: null,
    };
  },
  beforeMount() {
    this.init();
  },
  computed: {
    userInfo() {
      return this.dashboardInfo ? this.dashboardInfo.userInfo : null;
    },
    banners() {
      return this.dashboardInfo ? this.dashboardInfo.banners : [];
    },
    seriousNotifications() {
      return this.dashboardInfo ? this.dashboardInfo.seriousNotifications : [];
    },
    systemInfos() {
      var systemInfos = this.dashboardInfo ? this.dashboardInfo.systemInfos : [];
      systemInfos.forEach(function (info) {
        info.statusName = ProcStatus.of(info.status);
      });
      return systemInfos;
    },
  },
  methods: {
    init() {
      this.searchDashboard();
    },
    async searchDashboard() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("dashboard/search", {});
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        this.dashboardInfo = result;
      } catch (error) {
        console.error("Dashboard::searchDashboard", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
