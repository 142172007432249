<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" lg="9">
        <news-paper :seriousNotifications="seriousNotifications"></news-paper>
      </v-col>
      <v-col cols="12" md="4" lg="3">
        <sales-staff :userInfo="userInfo"></sales-staff>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="12">
        <new-products :banners="banners"></new-products>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
  </v-container>
</template>

<script>
import VendorViewMixin from "./../../mixins/vendorView";
import NewsPaper from "./common/NewsPaper.vue";
import SalesStaff from "./common/SalesStaff.vue";
import NewProducts from "./common/NewProducts.vue";

export default {
  name: "VendorDashboard",
  props: ["userInfo", "banners", "seriousNotifications"],
  mixins: [VendorViewMixin],
  components: {
    NewsPaper,
    SalesStaff,
    NewProducts,
  },
};
</script>
